<template>
  <div class="system-set">
    <div class="nav-box">
      <div
        class="item"
        @click="handleClick(i)"
        v-for="(item, i) in navList"
        :key="i"
        :style="{
          'border-color': index == i ? '#ee4d2d' : 'transparent',
          color: index == i ? '#ee4d2d' : '',
        }"
      >
        {{ item }}
      </div>
    </div>
    <div class="conter-box">
      <div style="width: 500px; padding: 20px 0" v-if="index == 0">
        <el-form size="mini" ref="form" label-width="120px">
          <el-form-item
            :label="item.title"
            v-for="(item, i) in options"
            :key="i"
          >
            <Upload
              :imageUrl="item.value"
              :urlList="item.url"
              :ids="item.ids"
              v-if="
                (item.key == 'site_logo' ||
                  item.key == 'front_logo' ||
                  item.key == 'favicon') &&
                show
              "
            ></Upload>
            <el-radio-group
              v-if="item.key == 'project_type'"
              v-model="item.value"
            >
              <el-radio label="0">本地</el-radio>
              <el-radio label="1">线上</el-radio>
            </el-radio-group>
            <el-input
              v-model="item.value"
              v-if="
                item.key != 'site_logo' &&
                item.key != 'front_logo' &&
                item.key != 'favicon' &&
                item.key != 'project_type'
              "
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-setting" type="primary" @click="onSubmit"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-if="index == 1" style="width: 600px; padding: 20px 0">
        <el-form size="mini" ref="form" label-width="120px">
          <el-form-item
            :label="item.title"
            v-for="(item, i) in options1"
            :key="i"
          >
            <el-radio-group
              v-if="item.key == 'upload_type'"
              v-model="item.value"
            >
              <el-radio label="1">本地</el-radio>
              <el-radio label="2">阿里云OSS</el-radio>
            </el-radio-group>
            <el-input
              style="max-width: 400px"
              v-model="item.value"
              v-else
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-setting" type="primary" @click="onSubmit"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-if="index == 2" style="width: 500px; padding: 20px 0">
        <el-form size="mini" ref="form" label-width="120px">
          <el-form-item
            :label="item.title"
            v-for="(item, i) in options2"
            :key="i"
          >
            <el-radio-group v-if="item.key == 'mail_type'" v-model="item.value">
              <el-radio label="1">STMP</el-radio>
              <el-radio label="2">MAIL</el-radio>
            </el-radio-group>
            <el-radio-group
              v-if="item.key == 'mail_verify_type'"
              v-model="item.value"
            >
              <el-radio label="1">None</el-radio>
              <el-radio label="2">TLS</el-radio>
              <el-radio label="3">SSL</el-radio>
            </el-radio-group>
            <el-input
              v-model="item.value"
              v-if="item.key != 'mail_verify_type' && item.key != 'mail_type'"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-setting" type="primary" @click="onSubmit"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-if="index == 3" style="width: 500px; padding: 20px 0">
        <el-form size="mini" ref="form" label-width="120px">
          <el-form-item
            :label="item.title"
            v-for="(item, i) in options3"
            :key="i"
          >
            <!-- <el-radio-group v-if="item.key == 'mail_type'" v-model="item.value">
              <el-radio label="1">STMP</el-radio>
              <el-radio label="2">MAIL</el-radio>
            </el-radio-group>
            <el-radio-group
              v-if="item.key == 'mail_verify_type'"
              v-model="item.value"
            >
              <el-radio label="1">None</el-radio>
              <el-radio label="2">TLS</el-radio>
              <el-radio label="3">SSL</el-radio>
            </el-radio-group> -->
            <el-input v-model="item.value"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-setting" type="primary" @click="onSubmit"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import Upload from "@/components/AdminUpload2";
import { setConfig, setConfigSet } from "@/api/admin.js";
export default {
  components: {
    Upload,
  },
  data() {
    return {
      index: 0,
      navList: ["基本配置", "文件上传配置", "邮件配置", "优优云平台配置"],
      options: [
        { title: "网站名称", key: "site_name", value: "" },
        { title: "网站地址", key: "site_url", value: "" },
        { title: "后台logo", key: "site_logo", value: [], ids: [], url: [] },
        { title: "前台logo", key: "front_logo", value: [], ids: [], url: [] },
        { title: "favicon图片", key: "favicon", value: [], ids: [], url: [] },
        { title: "SEO标题", key: "seo_title", value: "" },
        { title: "SEO关键词", key: "seo_keyword", value: "" },
        { title: "SEO描述", key: "seo_description", value: "" },
        { title: "项目类型", key: "project_type", value: "" },
        { title: "学校编号", key: "school", value: "" },
        { title: "公司名称", key: "company_name", value: "" },
        { title: "公司地址", key: "company_address", value: "" },
        { title: "联系人", key: "contact", value: "" },
        { title: "联系电话", key: "contact_mobile", value: "" },
        { title: "联系邮箱", key: "contact_email", value: "" },
        { title: "底部版权", key: "copyright", value: "" },
      ],
      options1: [
        { title: "上传类型", key: "upload_type", value: "" },
        { title: "空间域名 Domain", key: "upload_url", value: "" },
        { title: "accessKey", key: "access_key", value: "" },
        { title: "secretKey", key: "secret_key", value: "" },
        { title: "存储空间名称", key: "storage_name", value: "" },
        { title: "所属地域", key: "storage_region", value: "" },
        { title: "文件上传大小", key: "max_size", value: "" },
        { title: "存储路径", key: "oss_path", value: "" },
        
        { title: "文件预览域名", key: "oss_url", value: "" },
        { title: "视频播放accessKey", key: "video_access_key", value: "" },
        { title: "视频播放secretKey", key: "video_secret_key", value: "" },
        { title: "视频上传大小", key: "video_size", value: "" },
        { title: "视频播放域名", key: "video_url", value: "" },
        { title: "视频所属地域", key: "video_region", value: "" },

      ],
      options2: [
        { title: "邮件发送方式", key: "mail_type", value: "" },
        { title: "SMTP服务器", key: "mail_smtp_host", value: "" },
        { title: "SMTP端口", key: "mail_smtp_port", value: "" },
        { title: "SMTP用户名", key: "mail_smtp_user", value: "" },
        { title: "SMTP密码", key: "mail_smtp_pass", value: "" },
        { title: "SMTP验证方式", key: "mail_verify_type", value: "" },
        { title: "发件人邮箱", key: "mail_from", value: "" },
      ],
      options3: [
        { title: "accessKey", key: "access_key", value: "" },
        { title: "secretKey", key: "secret_key", value: "" },
        { title: "验证key", key: "auth_key", value: "" },
        { title: "iv", key: "iv", value: "" },
        { title: "访问地址", key: "url", value: "" },
      ],

      id1: "",
      id2: "",
      id3: "",
      id4: "",
      show: false,
    };
  },
  created() {
    this.getConFig();
  },
  methods: {
    getConFig() {
      setConfig({
        type: this.index,
      }).then((res) => {
        if (res.code == 1) {
          if (this.index == 0) {
            this.id1 = res.data["id"];
            this.options.forEach((item) => {
              if (item.key == "site_logo") {
                if (res.data["site_logo"] != "") {
                  item.value[0] = res.data["site_logo"];
                  item.ids[0] = res.data["admin_file"];
                } else {
                  item.value = [];
                  item.ids = [];
                }
              } else if (item.key == "front_logo") {
                if (res.data["front_logo"] != "") {
                  item.value[0] = res.data["front_logo"];
                  item.ids[0] = res.data["front_file"];
                } else {
                  item.value = [];
                  item.ids = [];
                }
              } else if (item.key == "favicon") {
                if (res.data["favicon"] != "") {
                  item.value[0] = res.data["favicon"];
                  item.ids[0] = res.data["favicon_file"];
                } else {
                  item.value = [];
                  item.ids = [];
                }
              } else {
                Object.keys(res.data).forEach((key) => {
                  if (item.key == key) {
                    item.value = res.data[key];
                  }
                });
              }
            });

            this.show = true;
          }
          if (this.index == 1) {
            this.id2 = res.data["id"];
            this.options1.forEach((item) => {
              Object.keys(res.data).forEach((key) => {
                if (item.key == key) {
                  item.value = res.data[key];
                }
              });
            });
          }
          if (this.index == 2) {
            this.id3 = res.data["id"];
            this.options2.forEach((item) => {
              Object.keys(res.data).forEach((key) => {
                if (item.key == key) {
                  item.value = res.data[key];
                }
              });
            });
          }
          if (this.index == 3) {
            this.id4 = res.data["id"];
            this.options3.forEach((item) => {
              Object.keys(res.data).forEach((key) => {
                if (item.key == key) {
                  item.value = res.data[key];
                }
              });
            });
          }
        }
      });
    },
    handleClick(i) {
      this.index = i;
      this.show = false;
      this.getConFig();
    },
    onSubmit() {
      let data = {};
      let obj = {};
      if (this.index == 0) {
        this.options.forEach((item) => {
          if (item.key == "site_logo") {
            obj["site_logo"] = item.value[0];
            obj["admin_file"] = item.ids[0];
          } else if (item.key == "front_logo") {
            obj["front_logo"] = item.value[0];
            obj["front_file"] = item.ids[0];
          } else if (item.key == "favicon") {
            obj["favicon"] = item.value[0];
            obj["favicon_file"] = item.ids[0];
          } else {
            obj[item.key] = item.value;
          }
        });
        data = {
          id: this.id1,
          basic: obj,
        };
      }
      if (this.index == 1) {
        this.options1.forEach((item) => {
          obj[item.key] = item.value;
        });
        data = {
          id: this.id2,
          files: obj,
        };
      }
      if (this.index == 2) {
        this.options2.forEach((item) => {
          obj[item.key] = item.value;
        });
        data = {
          id: this.id3,
          mail: obj,
        };
      }
      if (this.index == 3) {
        this.options3.forEach((item) => {
          obj[item.key] = item.value;
        });
        data = {
          id: this.id4,
          uuyun: obj,
        };
      }
      setConfigSet({
        ...data,
      }).then((res) => {
        if (res.code == 1) {

          this.$message.success(res.message);
        }
      });
    },
  },
};
</script>
<style lang="less" >
.system-set {
  padding: 20px;
  background: #fff;
  .nav-box {
    display: flex;
    border-bottom: 1px solid #eee;
    .item {
      padding: 0 20px 10px;
      font-size: 14px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
</style>